body {
  background-color: rgb(252, 246, 228);
  /* background-color: rgb(255, 255, 255); */
  background-color: white;
  /* background-image: url("/public/images/back1.png");
  background-position: right top;
*/
}

html {
  scroll-behavior: smooth;
}

.outbox {
  display: flex;
  flex-direction: column;
  border: solid red 0px;

  position: relative;
  height: 90vh;
  width: 1100px;
  margin: 0 auto;
  margin-top: 5vh;
  background-color: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ob_menu {
  width: 100%;
  height: 50px;
  border: solid green 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: fixed;
  margin: 0;
  top: 0;
  z-index: 99999999;
  background-color: black;
  color: white;
}

.ob_menu a {
  margin: 5px;
  text-decoration: none;
  color: white;
  padding: 0 10px;
}

.ob_body {
  width: auto;
  height: 50px;
  border: solid maroon 1px;
  flex-grow: 1;
  margin: 0px;
}

button {
  background-color: transparent;
  border: 0;
  border: solid red 0px;
  padding: 2px !important;
  margin: 0px !important;
  height: 30px;
  margin-left: 15px !important;
}

button:hover {
  font-weight: bold;
  cursor: pointer;
}

.outbox2 {
  display: flex;
  flex-direction: column;
  border: solid red 0px;

  position: relative;
  height: 90vh;
  width: 1100px;
  margin: 0 auto;
  margin-top: 55px;
}

.ob_body2 {
  width: auto;
  height: 10px;
  border: solid maroon 1px;
  width: 1100px;
  margin: 0 auto;
}

.box0 {
  width: 100%;
  height: auto;
  border: solid blue 0px;
  margin: 0 auto;
}

.box0:nth-child(odd) {
  background-color: white;
}
.box0:nth-child(even) {
  background-color: rgb(243, 238, 212);
  background-color: rgb(226, 240, 217);
}

.box2 {
  width: 100%;
  max-width: 1500px;
  height: auto;
  border: solid red 0px;
  margin: 10px;
  margin: 0 auto;
  padding: 59px 0;
}

.box2_100 {
  width: 100%;
  height: auto;
  border: solid red 0px;
}

.box5 {
  width: 100%;
  max-width: 1200px;
  height: auto;
  border: solid red 0px;
  margin: 50px;
  margin: 0 auto;
  padding: 29px 0;
}

.box05 {
  width: 100%;
  height: auto;
  border: solid blue 0px;
  margin: 0 auto;
}

.box05:nth-child(odd) {
  background-color: rgb(247, 252, 244);
}
.box05:nth-child(even) {
  background-color: white;
}

.section_title {
  font-size: 45px;
  font-weight: 600;
  margin-top: 47px;
  margin-bottom: 30px;
  border: solid green 0px;
  text-align: center;
}

.scroll_title {
  font-size: 20px;
  font-weight: 20px;
  margin-top: 5px;

  height: 1px;
  border: solid blue 0px;
}

.sapcetop {
  width: 100%;
  margin-top: 100px;
}

.subtext {
  margin: 0 45px;
  text-align: center;
}

.subtext_white {
  color: white;
  text-align: center;
  padding: 5px;
}

.contact_subtext {
  font-size: 25px;
  font-weight: bold;
  margin: 0 5px;
  margin-bottom: 10px;
  text-align: center;
}

.item_row_row {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.item_row_row a {
  text-decoration: none;
  margin: 5px;
}

.coursebox {
  width: 20%;
  height: auto;
  border: solid blue 0px;
  border-radius: 25px;
  margin: 7px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.coursebox img {
  width: 100%;
  height: auto;
}

.coursebox p,
.coursebox a {
  width: 100%;
  height: auto;
  text-align: center;
  font-weight: bold;
  font-size: 21px;
  margin-top: 20px;
}

.ytbox {
  width: 30%;
  height: auto;
  border: solid blue 0px;
  margin: 1%;
  border-radius: 15px;
}

iframe {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.btn_link {
  border: solid red 0px;
  padding: 15px !important;
  height: auto;
  border-radius: 5px;
}

.btn_t2 {
  background-color: rgb(207, 203, 203);
  color: black;
}

.btncolor_red {
  background-color: red;
  color: white;
}

.btncolor_blue {
  background-color: blue;
  color: white;
}

.btncolor_blue2 {
  background-color: rgb(35, 155, 215);
  color: white;
}

.btncolor_darkblue {
  background-color: rgb(4, 4, 138);
  color: white;
}

.btncolor_green {
  background-color: rgb(12, 160, 7);
  color: white;
}

.btncolor_green2 {
  background-color: rgb(0, 215, 95);
  color: white;
}

.btncolor_light {
  background-color: rgb(233, 243, 238);
  color: black;
  border: solid 2px rgb(92, 88, 88);
  font-weight: bold;
  padding: 12px !important;
  background-color: white;
}

.color_black {
  background-color: black;
}

.revwbox {
  width: 46%;
  height: auto;
  border: solid black 0px;
  margin: 1%;
  border-radius: 15px;
  background-color: white;
}

.revwbox img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.space20 {
  width: 100%;
  height: 20px;
}

.space30 {
  width: 100%;
  height: 30px;
}

.space40 {
  width: 100%;
  height: 40px;
}

.space50 {
  width: 100%;
  height: 50px;
}

.footer {
  display: block;

  height: 50px;
  background-color: black;
}

.footer p {
  padding: 15px !important;
  margin: 0 !important;
}

.flx_col {
  display: flex;
  flex-direction: row;
}

.coursedetails {
  display: flex;
  flex-direction: column;
}

.coursedetails .title {
  font-size: 36px;
  border-bottom: solid rgb(211, 202, 202) 2px;
}

.coursedetails img {
  width: 30%;
  height: auto;
}

.detailsTxt {
  font-size: 18px;
  color: black;
  text-align: justify;
  margin-bottom: 50px;
}

.mainbox {
  width: 100%;
  height: auto;
  border: solid black 0px;
  border-radius: 15px;
  /* background-image: url("/public/images/back1.png");
  background-position: right top; */
  text-align: center;
}

.mainbox img {
  width: 80%;
  height: auto;
  border-radius: 15px;
}
